export const env = {
  DEV: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev",
    TALKJS_APP_ID: "tgga7aVE",
  },
  DANTE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-dante`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-dante`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-dante`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-dante",
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRAN_CAJAL: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-fran`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-fran`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-fran`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-fran",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ROQUE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-roque`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-roque`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-roque`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-roque",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-leo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-leo`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-leo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LU: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-lu`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-lu`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-lu`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-lu",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUSTO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-justo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-justo`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-justo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-justo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LEANDRO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-leandro`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-leandro`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-leandro`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leandro",
    TALKJS_APP_ID: "tgga7aVE",
  },
  FRANCO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-franco`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-franco`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-franco`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-franco",
    TALKJS_APP_ID: "tgga7aVE",
  },
  BRUNO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-bruno`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-bruno`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-bruno`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-bruno",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JOAQUIN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-joaquin`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-joaquin`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-joaquin`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-joaquin",
    TALKJS_APP_ID: "tgga7aVE",
  },
  NACHO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-nacho`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nacho`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-nacho`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nacho",
    TALKJS_APP_ID: "tgga7aVE",
  },
  WALDEMAR: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-waldemar`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-waldemar`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-waldemar`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-waldemar",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ALEJO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-alejo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-alejo`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-alejo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-alejo",
    TALKJS_APP_ID: "tgga7aVE",
  },
  SIMON: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-simon`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-simon`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-simon`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-simon",
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-nicolas`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nicolas`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-nicolas`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nicolas",
    TALKJS_APP_ID: "tgga7aVE",
  },
  ESTEBAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-esteban`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-esteban`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-esteban`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-esteban",
    TALKJS_APP_ID: "tgga7aVE",
  },
  SHANE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-shane`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-shane`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-shane`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-shane",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JUANI: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-juani`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-juani`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-juani`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-juani",
    TALKJS_APP_ID: "tgga7aVE",
  },
  PABLOBA: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-pabloba`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-pabloba`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-pabloba`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-pabloba",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JULIAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-julian`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-julian`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-julian`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-julian",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MARCOS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-marcos`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-marcos`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-marcos`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-marcos",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MELANY: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-melany`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-melany`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-melany`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-melany",
    TALKJS_APP_ID: "tgga7aVE",
  },
  MATIAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-matias`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-matias`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-matias`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-matias",
    TALKJS_APP_ID: "tgga7aVE",
  },
  JULIO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-juliochort`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-juliochort`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-juliochort`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-juliochort",
    TALKJS_APP_ID: "tgga7aVE",
  },
  LUIS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://48jm5fzb0d.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_ADMIN_URL: `https://39thbqhoo4.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_LO_URL: `https://lgbumicmba.execute-api.us-east-1.amazonaws.com/dev-luis`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-luis",
    TALKJS_APP_ID: "tgga7aVE",
  },
  NICOLASF: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-nicolasf`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nicolasf`,
    API_LO_URL: `https://api.lo.nftydoor.com/dev-nicolasf`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nicolasf",
    TALKJS_APP_ID: "tgga7aVE",
  },
  PROD: {
    LO_FRONT_URL: "https://lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/prod`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/prod`,
    API_LO_URL: `https://api.lo.nftydoor.com/prod`,
    SMARTY_KEY: "118833480137188122",
    ENV: "prod",
    TALKJS_APP_ID: "tgga7aVE",
  },
};

export const ENV = ((localStorage.getItem("currentEviroment") as string) ??
  "DEV") as keyof typeof env;

function getEnvVariable(key: keyof typeof env.DEV) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;

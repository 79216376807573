import ScrollToTop from "components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { MainThemeUI } from "context/MaterialUITheme/MainThemeUI";
import { UserCustomerContextProvider } from "context/UserCustomer/useUserCustomerContext";
import AppRoutes from "AppRoutes";
import { UsePrivateLabelContextProvider } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import GoogleTagManager from "components/GoogleTagManager";

import "./App.css";
import { useEffect, useState } from "react";
import { FeatureMap } from "./typedef/FeatureFlag";
import API from "./utils/API";
import { FlagsProvider } from "flagged";

const App = () => {
  const [featureFlags, setFeatureFlags] = useState({});

  useEffect(() => {
    const getFeatureFlags = async () => {
      const result = await API.get<FeatureMap>(`/get/feature-flags`);

      if ("error" in result) {
        setFeatureFlags([]);
      } else {
        setFeatureFlags(result.data);
      }
    };

    getFeatureFlags();
  }, []);

  return (
    <FlagsProvider features={{ featureFlags }}>
      <UsePrivateLabelContextProvider>
        <GoogleTagManager />
        <MainThemeUI>
          <BrowserRouter>
            <UserCustomerContextProvider>
              <ScrollToTop>
                <AppRoutes />
              </ScrollToTop>
            </UserCustomerContextProvider>
          </BrowserRouter>
        </MainThemeUI>
      </UsePrivateLabelContextProvider>
    </FlagsProvider>
  );
};

export default App;

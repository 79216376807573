import { FeatureFlag } from "../typedef/FeatureFlag";

export const checkFeatureEnabled = (
  featureFlags: { [featureName: string]: FeatureFlag },
  featureName: string,
  userId: string,
  privateLabelScope?: string,
) => {
  //load feature flags and determine whether chat should be displayed. The conditions are:
  // for this private label, is the chat feature is enabled for the currently logged in admin user
  // in the general user list for the chat feature, is the feature enabled for the currently logged in admin user

  const privateLabelScopedUsers =
    featureFlags[featureName]?.privateLabelScope?.[privateLabelScope || ""] ||
    [];
  const unscopedUsers = featureFlags[featureName]?.userIds || [];
  return (
    privateLabelScopedUsers?.includes(userId) || unscopedUsers.includes(userId)
  );
};

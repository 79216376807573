import API from "./API";
export const getTalkjsToken = async (): Promise<string> => {
  const result = await API.get("/get/talkjs-token/");

  if ("error" in result) {
    return "";
  } else {
    return result.data as string;
  }
};

import { Typography, useTheme } from "@mui/material";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import dollarFormatter from "utils/FormatterDollar";
import { messageCardStyles } from "..";

type ProgressBarParameters = {
  step: BorrowerSteps | CoborrowerSteps | undefined;
  isBorrower: boolean;
};

type VerifiedSliderProps = {
  value: number;
  showValue: boolean;
};
const ProgressBarSection = ({ step, isBorrower }: ProgressBarParameters) => {
  const theme = useTheme();

  const VerifiedSlider = styled(Slider)<VerifiedSliderProps>(
    ({ value, showValue }) => ({
      color: "#34C155",
      height: 20,
      "& .MuiSlider-track": {
        backgroundColor: "#34C155",
        position: "relative",
        "&::before": {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "0.75rem",
          whiteSpace: "nowrap",
        },
        "&::after": {
          content: showValue
            ? `"${dollarFormatter.format(Number(value))}"`
            : '""',
          color: "black",
          fontWeight: 600,
          position: "absolute",
          right: -40,
          bottom: 25,
          fontSize: "1rem",
          whiteSpace: "nowrap",
        },
      },
      "& .MuiSlider-rail": {
        color: "red",
      },
      "& .MuiSlider-thumb": {
        display: "none",
      },
    }),
  );

  const incomeSource = {
    statedBy: isBorrower ? "B1" : "B2",
    annualIncome: step?.incomeVerification?.annualIncome ?? 0,
    totalIncome: step?.incomeVerification?.incomeSources
      ? Object.values(step?.incomeVerification?.incomeSources).reduce(
          (sum: number, value: number) => sum + Number(value),
          0,
        )
      : 0,
  };

  const selfEmployedData =
    step?.incomeVerification?.selfEmployed?.selfEmployedData?.filter(
      (income) => {
        return (
          income?.incomeSource &&
          income?.uploadedByBorrower &&
          !income?.debtIncome &&
          !income?.isIgnored
        );
      },
    );

  const additionalIncomeData =
    step?.incomeVerification?.selfEmployed?.selfEmployedData?.filter(
      (income) => {
        return (
          !income?.incomeSource &&
          income?.uploadedByBorrower &&
          !income?.debtIncome &&
          !income?.isIgnored
        );
      },
    );

  const difference = incomeSource.totalIncome - incomeSource.annualIncome;
  const totalIncomeIfExceded =
    incomeSource.annualIncome > incomeSource.totalIncome
      ? incomeSource.annualIncome
      : incomeSource.totalIncome;

  return (
    <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box key={incomeSource.statedBy} style={{ width: "100%" }}>
        <Box style={{ alignItems: "center" }}>
          {difference >= 1 && (
            <Box
              sx={{
                ...messageCardStyles,
                fontWeight: "bolder",
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Typography fontSize={{ xs: "16px", sm: "18px" }}>
                Your application stated you earn{" "}
                {dollarFormatter.format(incomeSource.totalIncome)}. We have
                verified {dollarFormatter.format(incomeSource.annualIncome)} so
                far.
                <br />
                To hold your current loan request,{" "}
                <span
                  style={{
                    fontWeight: "bolder",
                  }}
                >
                  you need to verify at least{" "}
                  {dollarFormatter.format(difference)} more.
                </span>
              </Typography>
            </Box>
          )}
          {selfEmployedData?.map((income) => (
            <Box key={income?.id} sx={messageCardStyles}>
              <Typography fontSize={{ xs: "16px", sm: "18px" }}>
                We are reviewing the document you provided to verify{" "}
                {dollarFormatter.format(income?.annual)} from{" "}
                {income?.incomeType}.
              </Typography>
            </Box>
          ))}
          {additionalIncomeData?.map((income) => (
            <Box
              key={income?.id}
              sx={{
                ...messageCardStyles,
                fontWeight: "bolder",
              }}
            >
              <Typography fontSize={{ xs: "16px", sm: "18px" }}>
                You added {dollarFormatter.format(income?.annual)} as Additional
                Income for {income?.incomeType}. We are verifying the document
                you provided.
              </Typography>
            </Box>
          ))}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={2}
            marginTop={3}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: "#34C155",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
              <Typography color="black" paddingLeft={0.5}>
                Verified Income
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  opacity: "50%",
                  backgroundColor: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
              <Typography color="black" paddingLeft={0.5}>
                Unverified Income
              </Typography>
            </Box>
          </Box>

          <Box
            style={{
              marginTop: 20,
              marginLeft: 25,
              marginRight: 25,
            }}
          >
            <VerifiedSlider
              value={incomeSource.annualIncome}
              showValue={difference >= 1}
              aria-labelledby="verification-slider"
              disabled
              min={0}
              max={incomeSource.totalIncome}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <span>{dollarFormatter.format(0)}</span>
            <span>{dollarFormatter.format(totalIncomeIfExceded)}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ProgressBarSection;

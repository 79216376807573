import useTracker from "components/CTracker/useTrackerContext";
import { useEffect, useMemo, useState } from "react";

const useJointAnnouncement = () => {
  const {
    loan,
    setIsModalOpen,
    isModalOpen,
    coborrowerInviteIsOpen,
    setCoborrowerInviteIsOpen,
  } = useTracker();
  const b2Exists = Boolean(loan?.coborrowerId || loan?.coborrowerInviteCode);
  const [openAnnouncement, setOpenAnnouncement] = useState(false);

  const ownerNames = useMemo(
    () =>
      loan?.borrowerSteps?.incomeVerification?.isJointAccount?.otherOwnerNames,
    [loan],
  );

  useEffect(() => {
    if (b2Exists || isModalOpen) {
      setOpenAnnouncement(false);
    } else if (
      loan?.borrowerSteps?.incomeVerification?.isJointAccount
        ?.isJointAccount === false &&
      !isModalOpen
    ) {
      setOpenAnnouncement(true);
    }
  }, [
    b2Exists,
    isModalOpen,
    loan?.borrowerSteps?.incomeVerification?.isJointAccount,
  ]);

  const handleAccept = async () => {
    setOpenAnnouncement(false);
    setIsModalOpen(false);
    setCoborrowerInviteIsOpen(true);
  };

  return {
    openAnnouncement,
    handleAccept,
    coborrowerInviteIsOpen,
    setCoborrowerInviteIsOpen,
    setIsModalOpen,
    ownerNames,
    setOpenAnnouncement,
  };
};

export default useJointAnnouncement;

import { Fragment } from "react";
import CButton from "components/CButton";
import CTermsDialog from "components/CTermsDialog";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import useRegisterFormCoborrower from "./useRegisterFormCoborrower";
import {
  Box,
  Link,
  List,
  Checkbox,
  MenuItem,
  ListItem,
  Container,
  Typography,
  useTheme,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import CNumberField from "components/CNumberField";
import CPasswordField from "components/CPasswordField";
import { passwordMsgHelper } from "CONST";
import { useInvite } from "context/Invite/useInviteContext";
import CVerificationDialog from "components/CVerificationDialog";
import useUser from "context/UserCustomer/useUserCustomerContext";
import CCommunicationsDialog from "components/CCommunicationsDialog";
import LoanOfficerInformation from "components/LoanOfficerInformation";
import moment from "moment";
import CLoading from "components/CLoading";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import {
  DemographicOptions,
  InnerPropsDemographicForm,
} from "components/CTracker/Steps/DemographicsVerification/DemographicDataDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { checkboxLabels } from "screens/BorrowerRegisterForm";
import dollarFormatter from "utils/FormatterDollar";
import { Controller } from "react-hook-form";
interface DividerProps {
  color: string;
}
const Divider: React.FC<DividerProps> = ({ color }) => (
  <div
    style={{
      borderBottom: `1px solid ${color}`,
      margin: "30px 0",
    }}
  />
);

type IncomeSourcesCoborrower =
  | {
      salaryIncome: string;
      selfEmploymentIncome: string;
      socialIncome: string;
      otherIncome: string;
    }
  | undefined;

const CoborrowerRegisterForm = () => {
  const user = useUser();
  const theme = useTheme();
  const invite = useInvite();
  const form = useRegisterFormCoborrower(invite);
  const setValue = form.setValue;
  const { privateLabel } = usePrivateLabel();
  const { errors } = form.formState;
  const control = form.control;

  const handleInputChangeCoborrower = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof typeof form.coborrowerIncomes,
  ) => {
    const { value } = e.target;
    const stringValue = typeof value === "string" ? value : String(value);
    const numericValue = stringValue.replace(/[$,\s]/g, "");
    const numberValue = parseFloat(numericValue);
    form.setCoborrowerIncomes((prev) => ({
      ...prev,
      [name]: isNaN(numberValue) ? 0 : numberValue,
    }));
  };

  const handleCheckboxChangeCoborrower = (
    checkbox: keyof typeof form.showIncomeInputsCoborrower,
  ) => {
    form.setShowIncomeInputsCoborrower((prev) => {
      const newState = { ...prev, [checkbox]: !prev[checkbox] };
      const coborrowerField =
        `${checkbox}` as keyof typeof form.coborrowerIncomes;
      if (!newState[checkbox]) {
        setValue(coborrowerField, undefined);
        handleInputChangeCoborrower(
          { target: { value: "" } } as React.ChangeEvent<HTMLInputElement>,
          coborrowerField,
        );
        if (coborrowerField === "salaryIncomeCoborrower") {
          setValue("salaryIncomeCoborrower", undefined);
          setValue("employerNameCoborrower", undefined);
          setValue("startDateCoborrower", undefined);
        }
      } else {
        const defaultValueOrganic =
          Number(invite?.annualIncomeSources?.[coborrowerField]) === 0 ||
          invite?.annualIncomeSources?.[coborrowerField] === undefined
            ? ""
            : invite?.annualIncomeSources?.[coborrowerField];

        const incomeSourcesCoborrower = invite?.body
          ?.incomeSourcesCoborrower as IncomeSourcesCoborrower;
        const newCoborrowerField = coborrowerField.slice(
          0,
          -10,
        ) as keyof typeof incomeSourcesCoborrower;

        const defaultValueInviteLoLink =
          incomeSourcesCoborrower &&
          incomeSourcesCoborrower[newCoborrowerField] === ""
            ? ""
            : incomeSourcesCoborrower?.[newCoborrowerField];
        const defaultValue =
          defaultValueOrganic === "" || defaultValueOrganic === undefined
            ? defaultValueInviteLoLink
            : defaultValueOrganic;
        setValue(coborrowerField, defaultValue ?? "");
        handleInputChangeCoborrower(
          {
            target: { value: defaultValue },
          } as React.ChangeEvent<HTMLInputElement>,
          coborrowerField,
        );
        if (coborrowerField === "salaryIncomeCoborrower") {
          setValue("employerNameCoborrower", invite?.employerNameCoborrower);
          setValue("startDateCoborrower", invite?.startDateCoborrower);
        }
      }
      form.trigger();
      return newState;
    });
  };

  const lenderName = privateLabel?.isNFTYDoor
    ? "Homebridge Financial Services, Inc."
    : privateLabel?.legalLenderName;
  const organizationName = privateLabel
    ? privateLabel.lenderName
    : `NFTYDoor's`;
  if (!invite) return <CLoading />;

  const isFormAddressDataValid =
    form?.addressData && Object?.keys(form?.addressData)?.length > 0
      ? form?.addressData
      : null;
  const isInviteAddressValid =
    invite?.address && Object?.keys(invite?.address)?.length > 0
      ? invite?.address
      : null;

  const address = isFormAddressDataValid ?? isInviteAddressValid ?? null;

  const disabledField = false;
  return (
    <Fragment>
      <Container maxWidth="md">
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3.2rem", md: "5.2rem" }}
          >
            <span style={{ color: theme.palette.primary.main }}>
              Hello {form.watch("firstName")}.
            </span>
            <br /> Register here.
          </Typography>
          <Typography
            mb={2}
            display={"flex"}
            variant="subtitle1"
            px={{ xs: 2, md: 0 }}
            justifyContent={"center"}
          >
            Please complete the steps below to begin the co-borrower process for
            the loan requested on {invite?.addressLine}
          </Typography>
        </Box>
        {invite?.loanOfficer?.firstName && (
          <LoanOfficerInformation
            nmls={invite?.loanOfficer?.NMLSNumber}
            company={invite.broker?.lenderName ?? privateLabel?.legalLenderName}
            lastName={invite?.loanOfficer.lastName}
            avatar={invite.loanOfficer.avatar}
            companyNMLS={
              invite.broker?.NMLScompany ?? privateLabel?.NMLScompany
            }
            firstName={invite.loanOfficer?.firstName}
            title={"WELCOME! I'm glad you're here!"}
            subtitle={`Interested in accessing your home equity? We now offer Home Equity
          Loans through ${organizationName} digital lending platform. Simply fill out the items below and see your prequalification results instantly. There is no obligation or credit check at this stage of the process, only if you choose to accept and move forward!`}
          />
        )}
        <Box
          borderRadius={10}
          bgcolor={theme.palette.background.paper}
          sx={{ scrollbarWidth: "none" }}
        >
          <Box px={{ xs: 2, md: 6 }} py={{ xs: 4, md: 5 }}>
            <form onSubmit={form.onSubmit}>
              <CTextField
                error={
                  !invite?.borrowerFullName
                    ? "Primary Borrower is required."
                    : undefined
                }
                fullWidth
                type="text"
                value={invite?.borrowerFullName}
                label="Primary Borrower"
                placeholder="John Doe"
                disabled
                sx={{ marginBottom: 3 }}
              />

              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <CTextField
                    label="Legal First Name"
                    placeholder="John"
                    fullWidth
                    autoComplete="off"
                    disabled={disabledField}
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.firstName?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                  <CTextField
                    label="Legal Last Name"
                    placeholder="Doe"
                    fullWidth
                    autoComplete="off"
                    disabled={disabledField}
                    sx={{ marginBottom: 3 }}
                    error={errors.lastName?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <CNumberField
                    fullWidth
                    autoComplete="off"
                    label="Cell Phone"
                    disabled={disabledField}
                    placeholder="+1(123)-123-1234"
                    format={
                      process.env.REACT_APP_ENV === "prod"
                        ? "+1###############"
                        : "+###############"
                    }
                    sx={{ mb: 3 }}
                    allowEmptyFormatting
                    {...field}
                    error={errors?.phoneNumber?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="maritalStatus"
                render={({ field }) => (
                  <CTextField
                    select
                    fullWidth
                    label="Marital Status"
                    disabled={disabledField}
                    sx={{ mb: 3 }}
                    {...field}
                    error={errors?.maritalStatus?.message}
                  >
                    <MenuItem value="Unmarried">Unmarried</MenuItem>
                    <MenuItem value="Married">Married</MenuItem>
                    <MenuItem value="Separated">Separated</MenuItem>
                  </CTextField>
                )}
              />
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-start"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                  <Controller
                    name="socialSecurityNumber"
                    control={control}
                    render={({ field }) => (
                      <CNumberField
                        fullWidth
                        label="Social Security Number"
                        type="text"
                        format={"###-##-####"}
                        placeholder="XXX-XX-XXXX"
                        sx={{ marginBottom: 3 }}
                        error={errors.socialSecurityNumber?.message}
                        {...field}
                        disabled={disabledField}
                      />
                    )}
                  />
                </Box>
                <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                  <Controller
                    name={"dateOfBirth"}
                    control={control}
                    render={({ field }) => (
                      <CTextField
                        fullWidth
                        type="date"
                        label="Date of birth"
                        disabled={disabledField}
                        InputProps={{
                          inputProps: {
                            min: "1908-02-05",
                            max: moment()
                              .subtract(18, "year")
                              .format("YYYY-MM-DD"),
                          },
                        }}
                        error={errors.dateOfBirth?.message}
                        {...field}
                        sx={{ marginBottom: 3 }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Controller
                name={"propertyAddress"}
                control={control}
                render={({ field }) => (
                  <CAddressAutocomplete
                    name="propertyAddressAutocomplete"
                    label="Mailing Address"
                    placeholder="Please the enter the address of where you live."
                    address={address}
                    setAddress={(newAddress) => {
                      form.clearErrors("propertyAddress");
                      if (!newAddress) {
                        return form.setAddressData(undefined);
                      } else {
                        form?.setAddressData(newAddress);
                      }
                    }}
                    sx={{ marginBottom: 3 }}
                    error={form?.formState?.errors?.propertyAddress?.message}
                    disabled={disabledField}
                  />
                )}
              />
              {form?.addressData?.street_line ===
                "My address is not listed" && (
                <>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                    gap={1}
                  >
                    <Box width={{ xs: "100%", md: "50%" }}>
                      <CTextField
                        label="Street Address"
                        placeholder="Street address"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.street_line
                            ?.message
                        }
                        {...form.register("manualAddress.street_line", {
                          required: "This field is required",
                          maxLength: {
                            value: 45,
                            message:
                              "The street name must be less than 45 letters or numbers.",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="Apt/Suite"
                        placeholder="Unit #"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        {...form.register("manualAddress.secondary")}
                      />
                    </Box>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"flex-start"}
                    flexDirection={{ xs: "column", md: "row" }}
                    gap={1}
                  >
                    <Box width={{ xs: "100%", md: "50%" }}>
                      <CTextField
                        label="City"
                        placeholder="City"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.city?.message
                        }
                        {...form.register("manualAddress.city", {
                          required: "This field is required",
                          maxLength: {
                            value: 45,
                            message: "The city must be less than 45 letters.",
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "City can only contain letters and spaces",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="State"
                        placeholder="State"
                        fullWidth
                        autoComplete="off"
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.state?.message
                        }
                        {...form.register("manualAddress.state", {
                          required: "This field is required",
                          minLength: {
                            value: 2,
                            message:
                              "This field must be exactly 2 characters long.",
                          },
                          maxLength: {
                            value: 2,
                            message:
                              "This field must be exactly 2 characters long.",
                          },
                          pattern: {
                            value: /^[A-Z]+$/,
                            message:
                              "This field must contain only uppercase letters",
                          },
                        })}
                      />
                    </Box>
                    <Box
                      width={{ xs: "100%", md: "50%" }}
                      ml={{ xs: 0, md: 1 }}
                    >
                      <CTextField
                        label="Zipcode"
                        placeholder="Zipcode"
                        fullWidth
                        autoComplete="off"
                        defaultValue={undefined}
                        sx={{ mb: 3 }}
                        error={
                          form?.formState?.errors?.manualAddress?.zipcode
                            ?.message
                        }
                        {...form.register("manualAddress.zipcode", {
                          required: "This field is required",
                          minLength: {
                            value: 5,
                            message: "Zipcode must be exactly 5 digits.",
                          },
                          maxLength: {
                            value: 5,
                            message: "Zipcode must be exactly 5 digits.",
                          },
                          pattern: {
                            value: /^\d+$/,
                            message: "Zipcode can only contain numbers",
                          },
                        })}
                      />
                    </Box>
                  </Box>
                </>
              )}

              <Divider color={theme.palette.text.disabled} />
              <Box mb={2}>
                <Typography
                  variant="h2"
                  lineHeight={1.2}
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  px={{ xs: 2, md: 0 }}
                  fontSize={{ xs: "1.5rem", md: "2rem" }}
                >
                  Annual Income
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  px={{ xs: 2, md: 0 }}
                >
                  Please provide more details
                </Typography>
              </Box>

              {form.incomeFieldsCoborrower.map((incomeField, index) => (
                <Box key={incomeField}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={form.showIncomeInputsCoborrower[incomeField]}
                      onChange={() =>
                        handleCheckboxChangeCoborrower(incomeField)
                      }
                    />
                    <Typography>{checkboxLabels[index]}</Typography>
                  </Box>

                  {form.showIncomeInputsCoborrower[incomeField] && (
                    <Box pl={5}>
                      <Controller
                        control={control}
                        name={incomeField}
                        render={({ field }) => (
                          <CNumberField
                            fullWidth
                            label={`$ Total Annual Compensation - ${checkboxLabels[index]}`}
                            placeholder="$85,000"
                            sx={{ mb: 1 }}
                            isNumericString
                            thousandSeparator
                            allowNegative={false}
                            allowEmptyFormatting={false}
                            disabled={disabledField}
                            prefix="$"
                            error={errors?.[incomeField]?.message}
                            {...field}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              field.onChange(
                                e.target.value
                                  .replace(/[$,\s]/g, "")
                                  .replace(/^0+(?=\d)/, ""),
                              );
                              handleInputChangeCoborrower(e, incomeField);
                            }}
                          />
                        )}
                      />

                      {incomeField === "salaryIncomeCoborrower" && (
                        <Box
                          width={"100%"}
                          display={"flex"}
                          alignItems={"flex-start"}
                          flexDirection={{ xs: "column", md: "column" }}
                        >
                          <Box
                            width={{ xs: "100%", md: "100%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="employerNameCoborrower"
                              render={({ field }) => (
                                <CTextField
                                  label="Employer Name"
                                  placeholder={privateLabel?.legalLenderName}
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3, mt: 2 }}
                                  {...field}
                                  error={
                                    errors?.employerNameCoborrower?.message
                                  }
                                />
                              )}
                            />
                          </Box>
                          <Box
                            width={{ xs: "100%", md: "100%" }}
                            mr={{ xs: 0, md: 1 }}
                          >
                            <Controller
                              control={control}
                              name="startDateCoborrower"
                              render={({ field }) => (
                                <CTextField
                                  type="date"
                                  label="Start Date"
                                  InputProps={{
                                    inputProps: {
                                      min: "1908-02-05",
                                      max: moment()
                                        .subtract(0, "year")
                                        .format("YYYY-MM-DD"),
                                    },
                                  }}
                                  placeholder="John"
                                  fullWidth
                                  autoComplete="off"
                                  disabled={disabledField}
                                  sx={{ mb: 3 }}
                                  {...field}
                                  error={errors?.startDateCoborrower?.message}
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
              <Box mt={3}>
                <Typography variant="h6" color={theme.palette.primary.main}>
                  Total Secondary Borrower Income:{" "}
                  {dollarFormatter.format(form.totalAnnualIncome)}
                </Typography>
              </Box>
              {errors.totalAnnualIncomeCoborrower && (
                <Typography
                  color={theme.palette.error.main}
                  fontWeight={400}
                  fontSize={"0.75rem"}
                >
                  {errors.totalAnnualIncomeCoborrower.message}
                </Typography>
              )}
              <Accordion
                sx={{
                  marginTop: "40px",
                  marginBottom: "10px",
                  boxShadow: "none",
                }}
                expanded={form.isAccordionOpen}
              >
                <AccordionSummary
                  onClick={form.handleAccordion}
                  expandIcon={<KeyboardArrowDownIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  data-cy="demographicsAccordion"
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={"1.5rem"}>Demographics</Typography>
                    <Typography sx={{ marginLeft: "5px", color: "red" }}>
                      (required)
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 1 }}>
                  <DemographicOptions
                    form={form as unknown as InnerPropsDemographicForm}
                  />
                </AccordionDetails>
              </Accordion>
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <CPasswordField
                    data-cy="password"
                    label="Create Password"
                    placeholder="Type in here"
                    fullWidth
                    autoComplete="new-password"
                    helperText={passwordMsgHelper}
                    {...field}
                    error={errors?.password?.message}
                  />
                )}
              />
              <List
                data-cy="checkBoxInviteCoBorrower"
                disablePadding
                sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}
              >
                <ListItem disableGutters alignItems="flex-start" sx={{ mt: 1 }}>
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("agree", !form.getValues().agree)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                  >
                    I agree to the{" "}
                    {privateLabel?.isNFTYDoor ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          padding: 0,
                          minWidth: "auto",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "none",
                          },
                        }}
                        onClick={() => {
                          form.setOpenedDialog("terms");
                        }}
                      >
                        Terms of Service
                      </Button>
                    ) : (
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy?.privacyTerms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </Link>
                    )}{" "}
                    and{" "}
                    <Link
                      color={theme.palette.primary.main}
                      textAlign={"justify"}
                      sx={{ cursor: "pointer", textDecoration: "none" }}
                      href={privateLabel?.footerPolicy.privacyPolicyLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                </ListItem>
                <ListItem disableGutters alignItems="flex-start">
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("certify", !form.getValues().certify)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                    textAlign={"justify"}
                  >
                    I certify that by checking this box I have read{" "}
                    {privateLabel?.isNFTYDoor ? (
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          cursor: "pointer",
                          textDecoration: "none",
                          padding: 0,
                          minWidth: "auto",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "transparent",
                            textDecoration: "none",
                          },
                        }}
                        onClick={() => {
                          form.setOpenedDialog("communications");
                        }}
                      >
                        NFTYDoor's Electronic Communications Policy
                      </Button>
                    ) : (
                      <Link
                        color={theme.palette.primary.main}
                        textAlign={"justify"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                        href={privateLabel?.footerPolicy?.privacyPolicyLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {privateLabel?.legalLenderName} Electronic
                        Communications Policy
                      </Link>
                    )}
                    , and consent to receive all legally required notices and
                    disclosures and other communications (“Communications”) from{" "}
                    {privateLabel
                      ? privateLabel.legalLenderName
                      : "NFTYDoor LLC"}{" "}
                    electronically and not on paper. I also confirm I am able to
                    access, view, retain and print all of the Communications{" "}
                    {privateLabel
                      ? privateLabel.legalLenderName
                      : "NFTYDoor LLC"}{" "}
                    provides me in electronic form.
                  </Typography>
                </ListItem>
                <ListItem disableGutters alignItems="flex-start" sx={{ mt: 1 }}>
                  <Box display="inline" fontFamily="Pro Display Semibold">
                    <Checkbox
                      sx={{ pl: 0 }}
                      onClick={() =>
                        form.setValue("consent", !form.getValues().consent)
                      }
                    />
                  </Box>
                  <Typography
                    mt={0.8}
                    variant="subtitle1"
                    color={theme.palette.text.primary}
                    textAlign={"justify"}
                  >
                    By clicking <b>"I Agree"</b>, I am providing ‘written
                    instructions’ to <b>{lenderName}</b> under the Fair Credit
                    Reporting Act authorizing <b>{lenderName}</b> to obtain
                    information from my personal credit profile or other
                    information related to my employment and income information.
                  </Typography>
                </ListItem>
              </List>

              <CButton
                data-cy="iAgree"
                fullWidth
                type="submit"
                variant="contained"
                loading={form.formState.isSubmitting || user.isGettingLoggedIn}
                disabled={
                  form.watch("agree") !== true ||
                  form.watch("certify") !== true ||
                  form.watch("consent") !== true
                }
                sx={{ mt: 4, mb: 2, fontSize: 18, textTransform: "inherit" }}
              >
                I Agree
              </CButton>
            </form>
          </Box>
        </Box>
      </Container>
      <CVerificationDialog
        phoneNumber={form.watch("phoneNumber")}
        open={form.openedDialog === "verify"}
        email={invite?.email?.trim()}
        onConfirmed={() => {
          form.setOpenedDialog(undefined);
          user.login(invite?.email as string, form.watch("password"));
        }}
      />
      <CDialog
        icon="warning"
        description={form.emailAlreadyUseOnPL}
        open={form.openedDialog === "EMAIL_IN_USE_ON_PL"}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            user.login(invite?.email as string, form.watch("password"));
            form.setOpenedDialog(undefined);
          }}
        >
          Continue
        </CButton>
      </CDialog>
      <CDialog
        icon="cancel"
        description={form.errorMessage}
        open={form.openedDialog === "error"}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => form.setOpenedDialog(undefined)}
        >
          Accept
        </CButton>
      </CDialog>
      <CTermsDialog
        open={form.openedDialog === "terms"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
      <CCommunicationsDialog
        open={form.openedDialog === "communications"}
        handleClose={() => form.setOpenedDialog(undefined)}
      />
    </Fragment>
  );
};

export default CoborrowerRegisterForm;

import API from "utils/API";
import { useEffect, useMemo, useState } from "react";
import Address from "typedef/Address";
import { useForm, UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useParseToSubmit from "./useParseToSubmit";
import parseMoney from "utils/parseMoney";
import useCalculateMaxLoan from "./useCalculateMaxLoan";
import PricingEngine from "typedef/PricingEngine";
import { passwordMsgHelper, regexPassword } from "CONST";
import statesList from "utils/statesList";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { getUseOfProceedsAdjuster } from "utils/getUseOfProceedsAdjuster";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import moment from "moment";
import useInvite from "context/Invite/useInviteContext";
import qs from "qs";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type IsCellphone = {
  data: {
    isValid: boolean;
    failed: string | null;
  };
};

type MasterLoanOfficer = {
  sk: string;
  NMLS: number;
  firstName: string;
  lastName: string;
  licenseNumber: string;
};

export type RegisterFormDialogs =
  | "terms"
  | "privacy"
  | "verify"
  | "error"
  | "communications"
  | "MAX_OFFER_ERROR"
  | "EMAIL_IN_USE_ON_PL"
  | "EXISTANT_USER_NOT_LOGGED";

type Ocuppancy = "Primary Residence" | "Second Home" | "Investment Property";

type CreditScoreOption = {
  label: string;
  value: string;
};

interface ResponseData {
  code: number;
  message: string;
  userAccountNoNeedVerification: {
    message: string;
  };
}

export type RegisterFormInputs = {
  borrowerAddress?: Address;
  propertyAddress: Address;
  PLMasterLoanOfficer?: MasterLoanOfficer;
  pricingEngineId?: string;
  totalAnnualIncomeBorrower: string;
  creditScoreRange: string;
  confirmEmailAddressBorrower: string;
  legalFirstNameBorrower: string;
  legalLastNameBorrower: string;
  phoneBorrower: string;
  maritalStatusBorrower: string;
  databaseIdPersona: string;
  reportIdPersona: string;
  inviteCode?: string;
  sourceId?: "fortuna" | "ladu";
  occupancyType: Ocuppancy;
  currentLoanBalance: string;
  requestedLoanAmount: string;
  useOfProceeds: string;
  employmentTypeBorrower: string;
  estimatedHomeValue: string;
  hasAcceptTerms: boolean;
  hasAcceptCertify: boolean;
  agreeNotifications: boolean;
  completedByBorrower: boolean;
  legalSuffixBorrower?: string;
  employerNameBorrower: string;
  startDateBorrower: string;
  password: string;
  manualAddress: Address & { street_number: string; street_suffix: string };
  manualBorrowerAddress: Address & {
    street_number: string;
    street_suffix: string;
  };
  salaryIncome?: string;
  selfEmploymentIncome?: string;
  socialIncome?: string;
  otherIncome?: string;
  legalMiddleNameBorrower?: string;
  inputsGroup?: {
    salaryIncome: string;
    selfEmploymentIncome: string;
    socialIncome: string;
    otherIncome: string;
  };
  legalFirstNameCoborrower?: string;
  legalLastNameCoborrower?: string;
  legalMiddleNameCoborrower?: string;
  legalSuffixCoborrower?: string;
  maritalStatusCoborrower?: string;
  phoneCoborrower?: string;
  confirmEmailAddressCoborrower?: string;
  salaryIncomeCoborrower?: string;
  selfEmploymentIncomeCoborrower?: string;
  socialIncomeCoborrower?: string;
  otherIncomeCoborrower?: string;
  startDateCoborrower?: string;
  employerNameCoborrower?: string;
  totalAnnualIncomeCoborrower?: string;
  incomeGroupCoborrower?: {
    salaryIncome: string;
    selfEmploymentIncome: string;
    socialIncome: string;
    otherIncome: string;
  };
  addCoborrower: string;
  employmentTypeCoborrower?: string;
  isLoggedIn: boolean;
};

const getIndexCreditScore = (
  pricingEngine: PricingEngine,
  creditScore: number,
) => {
  return pricingEngine?.rangesCreditScore?.findIndex(
    (item) => creditScore >= item.min && creditScore <= item.max,
  );
};

const getMarginRate = ({
  pricingEngine,
  cltv,
  creditScore,
}: {
  pricingEngine: PricingEngine;
  cltv: number;
  creditScore: number;
}) => {
  const indexCS = getIndexCreditScore(pricingEngine, creditScore);

  const indexCltv = pricingEngine?.rangesCltv?.findIndex((item) => {
    if (item.min === 0) {
      return (
        Number(cltv.toFixed(2)) >= item.min &&
        Number(cltv.toFixed(2)) <= item.max
      );
    } else {
      return (
        Number(cltv.toFixed(2)) > item.min &&
        Number(cltv.toFixed(2)) <= item.max
      );
    }
  });

  let marginRate = null;
  const margin = pricingEngine?.margin;
  if (indexCS !== -1 && indexCltv !== -1) {
    marginRate = margin?.[indexCS][indexCltv];
  }

  return marginRate;
};
const MAX_INCOME = 9999999.99;
const MIN_INCOME = 10000;
const MAX_PHONE_LENGTH = 12;
function useYupValidationSchema(pricingEngine: PricingEngine) {
  return useMemo(() => {
    return yup.object().shape({
      propertyAddress: yup
        .mixed()
        .required("Sub property address is required."),
      manualAddress: yup.object().when("propertyAddress", {
        is: (value: Address) =>
          value?.street_line === "My address is not listed",
        then: yup.object().shape({
          street_line: yup
            .string()
            .required("Street name is required")
            .max(
              45,
              "The street name must be less than 45 letters or numbers.",
            ),
          street_number: yup
            .string()
            .required("Street number is required")
            .max(8, "The street number must be less than 6 digits")
            .matches(
              /^[a-zA-Z0-9\\-]+$/,
              "The street number must be a number and cannot contain special characters or spaces",
            )
            .trim(),
          street_suffix: yup
            .string()
            .required("Street suffix is required")
            .max(6, "The street suffix must be less than 6 letters.")
            .matches(
              /^[a-zA-Z]+$/,
              "The street suffix must only contain letters and cannot contain numbers or special characters",
            ),
          secondary: yup.string(),
          city: yup
            .string()
            .required("City is required")
            .max(45, "The city must be less than 45 letters.")
            .matches(
              /^[A-Za-z\s]+$/,
              "City can only contain letters and spaces",
            ),
          zipcode: yup
            .string()
            .required("Zipcode is required")
            .length(5, "The zipcode must have 5 digits.")
            .matches(/^\d+$/, "Zipcode can only contain numbers"),
          state: yup
            .string()
            .required("State is required")
            .max(2, "Must be abbreviated (VA, TX, FL, etc)")
            .min(2, "Must be abbreviated (VA, TX, FL, etc)")
            .test("length", "State must be abbreviated", (value) => {
              if (!value) return true;
              else return value?.length === 2;
            })
            .test("list", "State is not valid", (value) => {
              if (!value) return true;
              else return statesList.includes(value);
            }),
        }),
        otherwise: yup.object().optional(),
      }),
      borrowerAddress: yup.mixed().when(
        ["occupancyType", "propertyAddress"],
        //@ts-ignore
        (occupancyType: Ocuppancy, propertyAddress: Address, schema) => {
          if (occupancyType !== "Primary Residence")
            return schema
              .required("Primary home address is required")
              .test(
                "sakeAddress",
                "Sub property address and Primary home address cannot be the same.",
                (borrowerValue: Address) => {
                  return borrowerValue?.street_line ===
                    "My address is not listed"
                    ? true
                    : JSON.stringify(propertyAddress) !==
                        JSON.stringify(borrowerValue);
                },
              );
        },
      ),
      manualBorrowerAddress: yup.object().when("borrowerAddress", {
        is: (borrowerAddress: Address) =>
          borrowerAddress?.street_line === "My address is not listed",
        then: yup.object().shape({
          street_line: yup
            .string()
            .required("Street is required")
            .max(
              45,
              "The street name must be less than 45 letters or numbers.",
            ),
          street_number: yup
            .string()
            .required("Street number is required")
            .max(8, "The street number must be less than 6 digits")
            .matches(
              /^[a-zA-Z0-9\\-]+$/,
              "The street number must be a number and cannot contain special characters or spaces",
            )
            .trim(),
          street_suffix: yup
            .string()
            .required("Street suffix is required")
            .max(6, "The street suffix must be less than 6 letters.")
            .matches(
              /^[a-zA-Z]+$/,
              "The street suffix must only contain letters and cannot contain numbers or special characters",
            ),
          secondary: yup.string(),
          city: yup
            .string()
            .required("City is required")
            .max(45, "The city must be less than 45 letters.")
            .matches(
              /^[A-Za-z\s]+$/,
              "City can only contain letters and spaces",
            ),
          zipcode: yup
            .string()
            .required("Zipcode is required")
            .length(5, "The zipcode must have 5 digits.")
            .matches(/^\d+$/, "Zipcode can only contain numbers"),
          state: yup
            .string()
            .required("State is required")
            .max(2, "Must be abbreviated (VA, TX, FL, etc)")
            .min(2, "Must be abbreviated (VA, TX, FL, etc)")
            .test("length", "State must be abbreviated", (value) => {
              if (!value) return true;
              else return value?.length === 2;
            })
            .test("list", "State is not valid", (value) => {
              if (!value) return true;
              else return statesList.includes(value);
            }),
        }),
        otherwise: yup.object().optional(),
      }),
      creditScoreRange: yup.string().required("Credit Score Range is required"),
      useOfProceeds: yup.string().required("Use of proceeds is required."),
      occupancyType: yup.string().required("Occupancy type is required."),

      employmentTypeBorrower: yup.string().optional(),
      employerNameBorrower: yup.string().when("salaryIncome", {
        is: (value: string) => value,
        then: yup.string().required("Employer name is required."),
        otherwise: yup.string().optional(),
      }),
      startDateBorrower: yup.string().when("salaryIncome", {
        is: (value: string) => value,
        then: yup
          .string()
          .required("Start date is required.")
          .test("min date", "Date must be after 1908-02-05", (value) => {
            return new Date(value as string) >= new Date("1908-02-05");
          })
          .test(
            "max date",
            `Date must be before ${moment().format("YYYY-MM-DD")}`,
            (value) => {
              return new Date(value as string) <= new Date();
            },
          ),
        otherwise: yup.string().optional(),
      }),

      confirmEmailAddressBorrower: yup
        .string()
        .email("Email has invalid format.")
        .trim()
        .test("unique-extension", "Email has invalid format.", (value) => {
          const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
          const uniqueExtensions = Array.from(new Set(extensions));
          return extensions.length === uniqueExtensions.length;
        })
        .required("Email is required."),
      phoneBorrower: yup
        .string()
        .trim()
        .required("Phone number is required.")
        .test(
          "minLenght",
          "The phone number must be 11 characters long.",
          (value) => {
            if (!value) return true;
            if (process.env.REACT_APP_ENV === "prod") {
              return value.replaceAll(/ /g, "").length === 12;
            }
            return true;
          },
        ),
      maritalStatusBorrower: yup
        .string()
        .required("Marital status is required."),
      legalFirstNameBorrower: yup
        .string()
        .required("Legal first name is required."),
      legalLastNameBorrower: yup
        .string()
        .required("Legal Last name is required."),
      legalSuffixBorrower: yup.string().optional(),
      estimatedHomeValue: yup
        .string()
        .required("Home value is required.")
        .test("min", "Home value cannot be less than $100,000", (value) => {
          if (!value) return true;
          if (parseMoney(value) < 100_000) return false;
          return true;
        }),
      currentLoanBalance: yup
        .string()
        .required("Current loan balance is required"),
      requestedLoanAmount: yup
        .string()
        .when(["currentLoanBalance"], (currentLoanBalance, schema) => {
          if (parseMoney(currentLoanBalance) === 0) {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMaxFirstLien}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMaxFirstLien
                  );
                },
              );
          } else {
            return schema
              .required("Request loan amount is required.")
              .test(
                "betweenAmount",
                `Loan Amount should be between $${pricingEngine?.loanMin} - $${pricingEngine?.loanMax}`,
                (value: string) => {
                  const amount = parseMoney(value);
                  return (
                    amount >= pricingEngine?.loanMin &&
                    amount <= pricingEngine?.loanMax
                  );
                },
              );
          }
        }),
      hasAcceptTerms: yup.boolean().required("Accept terms and conditions."),
      hasAcceptCertify: yup.boolean().required("Accept certify."),
      agreeNotifications: yup.boolean().required("Agree notifications."),
      isLoggedIn: yup.boolean().default(false),
      password: yup.string().when("isLoggedIn", {
        is: (value: boolean) => value === true,
        then: yup.string().notRequired(),
        otherwise: yup
          .string()
          .required("Password is required.")
          .max(100, "The password must be less than 100 characters.")
          .min(9, "The password must be at least 9 characters long.")
          .matches(regexPassword, passwordMsgHelper),
      }),
      salaryIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Salary income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      selfEmploymentIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Self employment income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      socialIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Social income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      otherIncome: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Other income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      inputsGroup: yup
        .object()
        .test(
          "at-least-one",
          "Income information is required to apply and create a valid offer afterwards",
          function () {
            const {
              salaryIncome,
              selfEmploymentIncome,
              socialIncome,
              otherIncome,
            } = this.parent;
            return (
              salaryIncome ||
              selfEmploymentIncome ||
              socialIncome ||
              otherIncome
            );
          },
        ),
      totalAnnualIncomeBorrower: yup
        .string()
        .test("max", "Maximum of 9 digits allowed.", function () {
          const {
            salaryIncome,
            selfEmploymentIncome,
            socialIncome,
            otherIncome,
          } = this.parent;
          const totalIncome = [
            parseMoney(salaryIncome),
            parseMoney(selfEmploymentIncome),
            parseMoney(socialIncome),
            parseMoney(otherIncome),
          ].reduce((sum, value) => sum + (value || 0), 0);

          return totalIncome <= MAX_INCOME;
        })
        .test("min", "The value cannot be less than $10,000.", function () {
          const {
            salaryIncome,
            selfEmploymentIncome,
            socialIncome,
            otherIncome,
          } = this.parent;
          const totalIncome = [
            parseMoney(salaryIncome),
            parseMoney(selfEmploymentIncome),
            parseMoney(socialIncome),
            parseMoney(otherIncome),
          ].reduce((sum, value) => sum + (value || 0), 0);

          return totalIncome >= MIN_INCOME;
        })
        .test(
          "min",
          "Total Annual Income must be greater than $0.",
          function () {
            const {
              salaryIncome,
              selfEmploymentIncome,
              socialIncome,
              otherIncome,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncome),
              parseMoney(selfEmploymentIncome),
              parseMoney(socialIncome),
              parseMoney(otherIncome),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome > 0;
          },
        ),
      addCoborrower: yup.string().optional(),
      legalFirstNameCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Legal first name is required."),
        otherwise: yup.string().optional(),
      }),
      legalLastNameCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Legal last name is required."),
        otherwise: yup.string().optional(),
      }),
      legalMiddleNameCoborrower: yup.string().optional(),
      legalSuffixCoborrower: yup.string().optional(),
      maritalStatusCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup.string().required("Marital status is required."),
        otherwise: yup.string().optional(),
      }),
      phoneCoborrower: yup
        .string()
        .trim()
        .when("addCoborrower", {
          is: (addCoborrower: string) => addCoborrower === "Yes",
          then: yup
            .string()
            .required("Phone number is required.")
            .test(
              "minLenght",
              "The phone number must be 11 characters long.",
              (value) => {
                if (!value) return true;
                if (process.env.REACT_APP_ENV === "prod") {
                  return value.replaceAll(/ /g, "").length === MAX_PHONE_LENGTH;
                }
                return true;
              },
            )
            .notOneOf(
              [yup.ref("phoneBorrower")],
              "Phone number must be different from the borrower's phone number.",
            ),
          otherwise: yup.string().optional(),
        }),
      confirmEmailAddressCoborrower: yup.string().when("addCoborrower", {
        is: (value: string) => value === "Yes",
        then: yup
          .string()
          .email("Email has invalid format.")
          .trim()
          .test("unique-extension", "Email has invalid format.", (value) => {
            const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
            const uniqueExtensions = Array.from(new Set(extensions));
            return extensions.length === uniqueExtensions.length;
          })
          .required("Email is required."),
        otherwise: yup.string().optional(),
      }),
      employerNameCoborrower: yup
        .string()
        .when(["addCoborrower", "salaryIncomeCoborrower"], {
          is: (addCoborrower: string, salaryIncomeCoborrower: string) =>
            addCoborrower === "Yes" && salaryIncomeCoborrower,
          then: yup.string().required("Employer name is required."),
          otherwise: yup.string().optional(),
        }),
      startDateCoborrower: yup
        .string()
        .when(["addCoborrower", "salaryIncomeCoborrower"], {
          is: (addCoborrower: string, salaryIncomeCoborrower: string) =>
            addCoborrower === "Yes" && salaryIncomeCoborrower,
          then: yup
            .string()
            .required("Start date is required.")
            .test("min date", "Date must be after 1908-02-05", (value) => {
              return new Date(value as string) >= new Date("1908-02-05");
            })
            .test(
              "max date",
              `Date must be before ${moment().format("YYYY-MM-DD")}`,
              (value) => {
                return new Date(value as string) <= new Date();
              },
            ),
          otherwise: yup.string().optional(),
        }),
      salaryIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Salary income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      selfEmploymentIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Self employment income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      socialIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Social income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      otherIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test(
            "is-greater-than-1000",
            "Other income must be greater than $1000.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue >= 1000;
            },
          )
          .test(
            "is-less-than-9999999",
            "Salary income must be less than $9,999,999.99.",
            (value) => {
              if (!value) return true;
              const numericValue = parseFloat(
                value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
              );
              return numericValue <= 9999999.99;
            },
          ),
        otherwise: yup.string().optional(),
      }),
      incomeGroupCoborrower: yup.object().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .object()
          .test(
            "at-least-one",
            "Income information is required to apply and create a valid offer afterwards",
            function () {
              const {
                salaryIncomeCoborrower,
                selfEmploymentIncomeCoborrower,
                socialIncomeCoborrower,
                otherIncomeCoborrower,
              } = this.parent;
              return (
                salaryIncomeCoborrower ||
                selfEmploymentIncomeCoborrower ||
                socialIncomeCoborrower ||
                otherIncomeCoborrower
              );
            },
          ),
      }),
      totalAnnualIncomeCoborrower: yup.string().when("addCoborrower", {
        is: (addCoborrower: string) => addCoborrower === "Yes",
        then: yup
          .string()
          .test("max", "Maximum of 9 digits allowed.", function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome <= MAX_INCOME;
          })
          .test("min", "The value cannot be less than $10,000.", function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome >= MIN_INCOME;
          })
          .test(
            "min",
            "Total Annual Income must be greater than $0.",
            function () {
              const {
                salaryIncomeCoborrower,
                selfEmploymentIncomeCoborrower,
                socialIncomeCoborrower,
                otherIncomeCoborrower,
              } = this.parent;
              const totalIncome = [
                parseMoney(salaryIncomeCoborrower),
                parseMoney(selfEmploymentIncomeCoborrower),
                parseMoney(socialIncomeCoborrower),
                parseMoney(otherIncomeCoborrower),
              ].reduce((sum, value) => sum + (value || 0), 0);

              return totalIncome > 0;
            },
          ),
        otherwise: yup.string().optional(),
      }),
    });
  }, [pricingEngine]);
}

const useRegisterForm = () => {
  const location = useLocation();
  const [creditScoreOptions, setCreditScoreOptions] = useState<
    CreditScoreOption[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorCode, setErrorCode] = useState<string>();
  const [openedDialog, setOpenedDialog] = useState<RegisterFormDialogs>();
  const [emailAlreadyUseOnPL, setEmailAlreadyUseOnPL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const parseToSubmit = useParseToSubmit();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState<
    { error?: string } | number
  >();
  const [isInvalidStateId, setIsInvalidStateId] = useState<boolean | string>(
    false,
  );
  const { user } = useUser();
  const { privateLabel } = usePrivateLabel();
  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();
  const [PLMasterLoanOfficer, setPLMasterLoanOfficer] = useState<
    MasterLoanOfficer | undefined
  >(undefined);
  const schema = useYupValidationSchema(pricingEngine as PricingEngine);
  const invite = useInvite();
  const loanOfficerId = invite?.loanOfficerId;
  const navigate = useNavigate();
  const form = useForm<RegisterFormInputs>({
    mode: "onChange",
    defaultValues: {
      propertyAddress: location.state as Address,
      borrowerAddress: undefined,
      PLMasterLoanOfficer: PLMasterLoanOfficer,
      pricingEngineId: pricingEngine?.id,
      creditScoreRange: creditScoreOptions[7]?.value,
      useOfProceeds: "",
      occupancyType: "Primary Residence",
      employmentTypeBorrower: "",
      confirmEmailAddressBorrower: "",
      legalFirstNameBorrower: "",
      phoneBorrower: process.env.REACT_APP_ENV === "prod" ? "+1" : "",
      legalLastNameBorrower: "",
      estimatedHomeValue: "",
      requestedLoanAmount: "",
      hasAcceptTerms: false,
      hasAcceptCertify: false,
      totalAnnualIncomeBorrower: "",
      agreeNotifications: false,
      currentLoanBalance: "",
      completedByBorrower: true,
      password: "",
      addCoborrower: "No",
      isLoggedIn: false,
    },
    resolver: yupResolver(schema),
  });

  const isLoggedIn = form.watch("isLoggedIn");

  useEffect(() => {
    if (user) {
      form.setValue("isLoggedIn", true);
    }
  }, [user, form, isLoggedIn]);

  const onSaveUnqualified = (error?: { id: string; message: string }) =>
    form.handleSubmit(async (data) => {
      setLoading(true);

      try {
        const response = await API.post({
          url: `/save-unqualified`,
          data: {
            ...parseToSubmit(data),
            error,
            password: undefined,
          },
        });
        if ("error" in response) {
          setErrorMessage(response.error);
          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR" && privateLabel) {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support:\n\nsupport@nftydoor.com",
          );
          setOpenedDialog("error");
        } else if (openedDialog === "MAX_OFFER_ERROR") {
          setErrorMessage(
            "Unfortunately based on our underwriting guidelines we are unable to offer you a loan.\n\nPlease feel free to reach out to support:\n\nsupport@nftydoor.com",
          );
          setOpenedDialog("error");
        }
      } catch (unknownError) {
        setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
      setLoading(false);
    })();

  const requestedLoanAmount = parseMoney(form.watch("requestedLoanAmount"));
  const currentLoanBalance = parseMoney(form.watch("currentLoanBalance"));

  const validateEmptyFields = (
    data: RegisterFormInputs,
    form: UseFormReturn<RegisterFormInputs>,
  ) => {
    const errors: { [key: string]: string } = {};
    let hasError = false;

    if (data.salaryIncome === "") {
      errors.salaryIncome = "Salary income is required.";
      hasError = true;
    }
    if (data.selfEmploymentIncome === "") {
      errors.selfEmploymentIncome = "Self employment income is required.";
      hasError = true;
    }
    if (data.socialIncome === "") {
      errors.socialIncome = "Social income is required.";
      hasError = true;
    }
    if (data.otherIncome === "") {
      errors.otherIncome = "Other income is required.";
      hasError = true;
    }
    if (data.salaryIncomeCoborrower === "") {
      errors.salaryIncomeCoborrower =
        "Salary income (co-borrower) is required.";
      hasError = true;
    }
    if (data.selfEmploymentIncomeCoborrower === "") {
      errors.selfEmploymentIncomeCoborrower =
        "Self employment income (co-borrower) is required.";
      hasError = true;
    }
    if (data.socialIncomeCoborrower === "") {
      errors.socialIncomeCoborrower =
        "Social income (co-borrower) is required.";
      hasError = true;
    }
    if (data.otherIncomeCoborrower === "") {
      errors.otherIncomeCoborrower = "Other income (co-borrower) is required.";
      hasError = true;
    }
    Object.keys(errors).forEach((key) => {
      form.setError(key as keyof RegisterFormInputs, {
        type: "required",
        message: errors[key],
      });
    });

    return hasError;
  };

  const onSubmit = form.handleSubmit(async (data) => {
    const hasEmptyField = validateEmptyFields(data, form);
    if (hasEmptyField) return;
    setLoading(true);

    const phoneNumbers = {
      phoneBorrower: data.phoneBorrower,
      phoneCoborrower: data.phoneCoborrower,
    };

    const queryString = qs.stringify(phoneNumbers);

    try {
      const response = (await API.get(
        `/getServiceUnsecured/validate-phone-number?${queryString}`,
      )) as IsCellphone;

      const isCellPhone = response.data;

      if (!isCellPhone?.isValid) {
        const failedField =
          isCellPhone?.failed === "phoneBorrower"
            ? "phoneBorrower"
            : "phoneCoborrower";
        form.setError(failedField, {
          type: "manual",
          message: `Please provide a valid cell phone number for ${
            failedField === "phoneBorrower" ? "the borrower" : "the co-borrower"
          } so that you are notified of the next steps of your application.`,
        });
        return;
      }
    } catch (error) {
      setErrorMessage(`Error validating phone numbers: ${error}`);
    }
    const newData = {
      ...data,
      addCoborrower: data.addCoborrower,
      isB1Mobile: true,
      isB2Mobile: data.addCoborrower === "Yes" ? true : undefined,
    };
    try {
      const response = await API.post<ResponseData>({
        url: `/register-user/borrowerInviteLink`,
        data: parseToSubmit({ ...newData, PLMasterLoanOfficer, isLoggedIn }),
      });
      if ("error" in response) {
        if (response.errorId === "EXISTANT_USER_NOT_LOGGED") {
          setErrorMessage(response.error);
          setOpenedDialog("EXISTANT_USER_NOT_LOGGED");
        } else {
          setOpenedDialog(
            response.errorId === "MAX_OFFER_ERROR"
              ? "MAX_OFFER_ERROR"
              : "error",
          );
          setErrorMessage(response.error);
          setErrorCode(response.errorId);
          response.errorId !== "MAX_OFFER_ERROR" &&
            onSaveUnqualified(
              response.errorId
                ? {
                    id: response.errorId ?? "",
                    message: response.error ?? "",
                  }
                : undefined,
            );
        }
      } else {
        const data = response.data;
        if (data?.userAccountNoNeedVerification?.message) {
          setEmailAlreadyUseOnPL(
            response.data.userAccountNoNeedVerification.message,
          );
          setOpenedDialog("EMAIL_IN_USE_ON_PL");
        } else {
          setOpenedDialog("verify");
          setShowSuccessDialog(true);
          navigate(`/submitted?mloid=${loanOfficerId}`);
        }
      }
    } catch (unknownError) {
      setErrorMessage(String(unknownError));
      console.error(unknownError);
    }
    setLoading(false);
  });

  const homeValue = parseMoney(form.getValues().estimatedHomeValue);
  const cltvValue = useMemo(() => {
    const cltv = (currentLoanBalance + requestedLoanAmount) / homeValue;
    return cltv;
  }, [requestedLoanAmount, homeValue, currentLoanBalance]);

  const propertyAddress = form.watch("propertyAddress");
  const useOfProceedsSelectedValue = form.watch("useOfProceeds");

  const currMaxCreditScoreValue = parseFloat(
    form.getValues()?.creditScoreRange?.includes("-")
      ? form.getValues()?.creditScoreRange?.split(" - ")[1]
      : form.getValues()?.creditScoreRange,
  );

  const creditScoreRange = form.watch("creditScoreRange");

  const { maxLoanAmount: calcLoanAmount, pricingEngine: pricingEngineData } =
    useCalculateMaxLoan(form.watch);

  useEffect(() => {
    if (user?.email) {
      form.reset({
        confirmEmailAddressBorrower: user?.email,
        legalFirstNameBorrower: user?.firstName,
        legalLastNameBorrower: user?.lastName,
        phoneBorrower: user?.phoneNumber,
        agreeNotifications: true,
      });
    }
  }, [form, user]);

  useEffect(() => {
    const options =
      pricingEngine?.rangesCreditScore?.map((range, index, array) => {
        const fullRange = `${range.min} - ${range.max}`;
        if (index === array.length - 1) {
          return { label: "I don't know", value: String(range?.max) };
        }
        return { label: fullRange, value: fullRange };
      }) ?? [];
    setCreditScoreOptions(options);
  }, [pricingEngine, creditScoreRange]);

  useEffect(() => {
    setMaxLoanAmount(calcLoanAmount);
  }, [calcLoanAmount]);

  useEffect(() => {
    const validateRequestAmount = (
      currentLoanBalance: number,
      propertyAddress: Address,
    ) => {
      if (!requestedLoanAmount) return false;
      if (
        requestedLoanAmount <
          (propertyAddress?.state === "MN" ? 100000 : 25000) ||
        requestedLoanAmount > maxLoanAmount ||
        requestedLoanAmount >
          (currentLoanBalance === 0
            ? pricingEngine?.loanMaxFirstLien ?? 400000
            : pricingEngine?.loanMax ?? 400000)
      )
        return true;
    };

    if (!cltvValue) return;
    setLoading(true);
    const data = {
      initialDrawAmount: requestedLoanAmount,
      cltv: cltvValue,
      creditScore: currMaxCreditScoreValue,
      currentLoanBalance: currentLoanBalance,
      useOfProceeds: useOfProceedsSelectedValue,
      pricingEngineId: pricingEngine?.id,
    };
    const timeOut = setTimeout(() => {
      const validateAmount = validateRequestAmount(
        requestedLoanAmount,
        propertyAddress,
      );

      if (validateAmount === false) {
        setInterestRate({
          error:
            "We cannot provide an offer because FICO is too low. Please archive this application and reapply later",
        });
      } else {
        API.post<{
          monthlyInterestCharge: number;
          interestRate: number;
          marginRate: number;
          primeRate: number;
        }>({
          url: `/get/interestRate`,
          data,
        })
          .then((result) => {
            if ("error" in result) {
              setInterestRate(undefined);
            } else setInterestRate(result?.data?.interestRate);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 1500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [
    cltvValue,
    currMaxCreditScoreValue,
    currentLoanBalance,
    propertyAddress,
    maxLoanAmount,
    requestedLoanAmount,
    useOfProceedsSelectedValue,
    pricingEngine?.loanMax,
    pricingEngine?.loanMaxFirstLien,
    pricingEngine,
  ]);

  const offertOptionsByFico = useMemo(() => {
    if (
      (!requestedLoanAmount || !maxLoanAmount || !currMaxCreditScoreValue) &&
      loading
    )
      return [];
    if (!pricingEngineData) {
      return;
    }

    const indexCreditScore = getIndexCreditScore(
      pricingEngineData,
      currMaxCreditScoreValue,
    );

    const rowsNumber =
      pricingEngineData?.margin?.[indexCreditScore]?.filter((el) => el !== null)
        .length ?? 0;

    const offers: {
      maxLoanAmount: number;
      rate: number | undefined;
    }[] = [];

    for (let index = 0; index < rowsNumber; index++) {
      const currMaxCltv = pricingEngineData?.rangesCltv[index]?.max;

      const newRequestedLoanAmount =
        currMaxCltv * homeValue - currentLoanBalance;

      const marginRate = getMarginRate({
        pricingEngine: pricingEngineData,
        cltv: currMaxCltv,
        creditScore: currMaxCreditScoreValue,
      });

      const loanPurposeValue = getUseOfProceedsAdjuster(
        pricingEngineData?.marginAdjusters?.loanPurpose,
        useOfProceedsSelectedValue,
      );

      let interestRateValue;

      if (pricingEngineData?.primeRate !== undefined)
        if (
          currentLoanBalance === 0 &&
          pricingEngineData?.firstLienDiscount &&
          marginRate &&
          loanPurposeValue
        ) {
          interestRateValue =
            marginRate +
            loanPurposeValue +
            pricingEngineData?.primeRate -
            pricingEngineData?.firstLienDiscount;
        } else {
          interestRateValue =
            (marginRate ?? 0) + pricingEngineData?.primeRate + loanPurposeValue;
        }

      if (newRequestedLoanAmount > requestedLoanAmount) {
        if (currentLoanBalance === 0) {
          if (newRequestedLoanAmount < pricingEngineData?.loanMaxFirstLien) {
            offers.push({
              maxLoanAmount: newRequestedLoanAmount,
              rate: interestRateValue,
            });
          } else if (
            !offers?.some(
              (offer) =>
                offer.maxLoanAmount === pricingEngineData?.loanMaxFirstLien,
            )
          ) {
            offers.push({
              maxLoanAmount: pricingEngineData?.loanMaxFirstLien,
              rate: interestRateValue,
            });
          }
        } else if (newRequestedLoanAmount < pricingEngineData?.loanMax) {
          offers.push({
            maxLoanAmount: newRequestedLoanAmount,
            rate: interestRateValue,
          });
        } else if (
          !offers?.some(
            (offer) => offer.maxLoanAmount === pricingEngineData?.loanMax,
          )
        ) {
          offers.push({
            maxLoanAmount: pricingEngineData?.loanMax,
            rate: interestRateValue,
          });
        }
      }
    }

    return offers;
  }, [
    maxLoanAmount,
    currentLoanBalance,
    homeValue,
    loading,
    pricingEngineData,
    currMaxCreditScoreValue,
    requestedLoanAmount,
    useOfProceedsSelectedValue,
  ]);

  const currentOccupancy = form.watch("occupancyType");
  const currentState =
    form.watch("propertyAddress.state") || "manualAddress.state";

  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(currentOccupancy);
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [currentOccupancy, privateLabel]);

  const currentManualState = form.watch("manualAddress.state");
  const currentPropertyAddress = form.watch("propertyAddress");

  useEffect(() => {
    const currentStreetLine = currentPropertyAddress?.street_line;
    const statesLicensed = privateLabel?.statesLicensed;

    function handleListedAddrees() {
      const currentState = currentPropertyAddress?.state;
      if (currentState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentState.toUpperCase(),
        );

        if (currentState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentState}`,
          );
        } else if (
          currentState === "NY" &&
          privateLabel?.newYorkApplicationsDomain &&
          !privateLabel?.newYorkApplicationsDomain?.includes(
            window.location.hostname,
          )
        ) {
          setIsInvalidStateId(
            `The company is not authorized to operate in this state: ${currentState}, Please hold as we need to direct you to our NY application site ${privateLabel.newYorkApplicationsDomain}`,
          );
          setTimeout(() => {
            window.location.href = `${privateLabel.newYorkApplicationsDomain}${location.pathname}${location.search}`;
          }, 5000);
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    function handleNotListedAddrees() {
      if (currentManualState && statesLicensed) {
        const isStateLicensed = statesLicensed.find(
          (license) =>
            license.state_id.toUpperCase() === currentManualState.toUpperCase(),
        );

        if (currentManualState.length > 1 && !isStateLicensed) {
          setIsInvalidStateId(
            `The company is not authorized to lend in this State: ${currentManualState}`,
          );
        } else if (
          currentManualState === "NY" &&
          privateLabel?.newYorkApplicationsDomain &&
          !privateLabel?.newYorkApplicationsDomain?.includes(
            window.location.hostname,
          )
        ) {
          setIsInvalidStateId(
            `The company is not authorized to operate in this state: ${currentManualState}, Please hold as we need to direct you to our NY application site ${privateLabel.newYorkApplicationsDomain}`,
          );
          setTimeout(() => {
            window.location.href = `${privateLabel.newYorkApplicationsDomain}${location.pathname}${location.search}`;
          }, 5000);
        } else {
          setIsInvalidStateId(false);
        }
      }
    }

    if (currentStreetLine !== "My address is not listed") {
      handleListedAddrees();
    } else if (currentStreetLine === "My address is not listed") {
      handleNotListedAddrees();
    } else {
      setIsInvalidStateId(false);
    }
  }, [
    currentManualState,
    currentPropertyAddress?.state,
    currentPropertyAddress?.street_line,
    privateLabel,
    location.pathname,
    location.search,
  ]);

  return {
    interestRate,
    showSuccessDialog,
    setShowSuccessDialog,
    offertOptionsByFico,
    requestedLoanAmount,
    onSaveUnqualified,
    onSubmit,
    openedDialog,
    errorMessage,
    setOpenedDialog,
    emailAlreadyUseOnPL,
    setErrorMessage,
    errorCode,
    loading,
    propertyState: propertyAddress?.state,
    currentState,
    isInvalidStateId,
    PLMasterLoanOfficer,
    setPLMasterLoanOfficer,
    creditScoreOptions,
    ...form,
  };
};

export default useRegisterForm;
